<template>
  <b-card-code>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Producto"
          >
            <v-select
              v-model="item.product"
              label="label"
              :options="products"
              @input="selectProduct(item.product)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Nº Cuenta Gasto de Minuta"
            label-for="account"
          >
            <b-form-input
              id="account"
              v-model="item.accountNumberOutgo"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Nº Cuenta Gasto de Servicios Legales"
            label-for="account2"
          >
            <b-form-input
              id="account2"
              v-model="item.accountNumberLegal"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="mt-3 mb-1">
            Límites por rango
          </h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b-form-group
            label="Límite Inferior"
            label-for="limit1"
          >
            <b-form-input
              id="limit1"
              v-model="subItem.lowerLimit"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Límite Superior"
            label-for="limit1"
          >
            <b-form-input
              id="limit1"
              v-model="subItem.topLimit"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Montos Legales"
            label-for="limit1"
          >
            <b-form-input
              id="limit1"
              v-model="subItem.legalAmount"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Monto de Minutas"
            label-for="limit1"
          >
            <b-form-input
              id="limit1"
              v-model="subItem.outgoAmount"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mt-2"
        >
          <b-button-group>
            <b-button
              :disabled="!item.product"
              @click="add(subItem)"
            >
              Agregar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="loading"
              class="mr-1"
            >
              <span v-if="!loading">Guardar</span>
              <span v-if="loading">Un momento...</span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <p>{{ errorMessage }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-table
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="remove(data)"
              >
                Borrar
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-form>

    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="reset"
      variant="outline-secondary"
      to="mortgage-range"
      class="mt-1"
    >
      Cancelar
    </b-button>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BTable, BButtonGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BButtonGroup,
    BTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      centers: [],
      columns: [
        {
          label: 'Limite Inferior',
          key: 'limiteInferior',
          formatter: this.currency,
        },
        {
          label: 'Limite Superior',
          key: 'limiteSuperior',
          formatter: this.currency,
        },
        {
          label: 'Montos Legales',
          key: 'montoLegales',
          formatter: this.currency,
        },
        {
          label: 'Monto Minutas',
          key: 'montoMinutas',
          formatter: this.currency,
        },
        {
          label: '',
          key: 'actions',
        },
      ],
      errorMessage: '',
      generateOptions: ['S', 'N'],
      loading: false,
      item: {
        accountNumberOutgo: '4105230000000000',
        accountNumberLegal: '4105260000000000',
      },
      items: [],
      subItem: {
        lowerLimit: 0,
        topLimit: 0,
        legalAmount: 0,
        outgoAmount: 0,
      },
      products: [
        {
          code: 1020,
          label: 'Viviendas Nuevas No Preferencial (1020)',
        },
        {
          code: 1022,
          label: 'Viviendas Nuevas No Preferencial (1022)',
        },
        {
          code: 1024,
          label: 'LPT (1024)',
        },
        {
          code: 1025,
          label: 'LPT (1025)',
        },
        {
          code: 1026,
          label: 'Viviendas Nuevas Preferencial    (1026)',
        },
        {
          code: 1028,
          label: 'Viviendas Nuevas Preferencial    (1028)',
        },
        {
          code: 1032,
          label: 'Viviendas Nuevas No Preferencial (1032)',
        },
        {
          code: 1040,
          label: 'Viviendas Nuevas No Preferencial (1040)',
        },
        {
          code: 1041,
          label: 'Consumo, Refinanciamientos, Traslados, Mejoras, Lotes Servicios (1041)',
        },
        {
          code: 1042,
          label: 'Viviendas Usadas (1042)',
        },
        {
          code: 1045,
          label: 'Viviendas Nuevas No Preferencial (1045)',
        },
        {
          code: 1047,
          label: 'Viviendas Nuevas No Preferencial (1047)',
        },
        {
          code: 1050,
          label: 'Consumo, Refinanciamientos, Traslados, Mejoras, Lotes Servicios (1050)',
        },
        {
          code: 1051,
          label: 'Consumo, Refinanciamientos, Traslados, Mejoras, Lotes Servicios (1051)',
        },
        {
          code: 1062,
          label: 'Viviendas Nuevas Preferencial (1062)',
        },
        {
          code: 1063,
          label: 'Viviendas Nuevas Preferencial (1063)',
        },
        {
          code: 1064,
          label: 'Viviendas Nuevas Preferencial (1064)',
        },
        {
          code: 1065,
          label: 'Viviendas Nuevas Preferencial (1065)',
        },
        {
          code: 1066,
          label: 'Viviendas Nuevas Preferencial (1066)',
        },
        {
          code: 1067,
          label: 'Viviendas Nuevas Preferencial (1067)',
        },
        {
          code: 1071,
          label: 'Viviendas Usadas (1071)',
        },
        {
          code: 1072,
          label: 'Viviendas Usadas (1072)',
        },
        {
          code: 1075,
          label: 'Consumo, Refinanciamientos, Traslados, Mejoras, Lotes Servicios (1075)',
        },
        {
          code: 1087,
          label: 'Viviendas Nuevas No Preferencial (1087)',
        },
        {
          code: 1090,
          label: 'Viviendas Usadas (1090)',
        },
        {
          code: 1095,
          label: 'Consumo, Refinanciamientos, Traslados, Mejoras, Lotes Servicios (1095)',
        },
        {
          code: 1096,
          label: 'Consumo, Refinanciamientos, Traslados, Mejoras, Lotes Servicios (1096)',
        },
        {
          code: 1130,
          label: 'LPT (1130)',
        },
        {
          code: 1194,
          label: 'LPT (1194)',
        },
      ],
      options: {
        prefix: {
          prefix: ' %',
          numeralIntegerScale: 2,
          numeralDecimalScale: 2,
          numeral: true,
          tailPrefix: true,
          numeralPositiveOnly: true,
          rawValueTrimPrefix: true,
        },
      },
    }
  },
  computed: {
    statuses() {
      return this.$store.getters['catalog/allStatuses']
    },
  },
  methods: {
    add(item) {
      this.errorMessage = ''

      const lowerLimit = Number(item.lowerLimit)
      const topLimit = Number(item.topLimit)
      const legalAmount = Number(item.legalAmount)
      const outgoAmount = Number(item.outgoAmount)

      if (item && lowerLimit && topLimit && legalAmount && outgoAmount) {
        const exists = this.items.find(it => {
          const limiteInferior = Number(it.limiteInferior)
          const limiteSuperior = Number(it.limiteSuperior)

          return (limiteInferior === lowerLimit && limiteSuperior === topLimit) || (lowerLimit >= limiteInferior &&  topLimit <= limiteSuperior)
        })

        if(exists) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Este rango ya existe',
              variant: 'danger',
              icon: 'BellIcon',
            },
          })

        } else {
          this.push(item)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Datos no validos',
            variant: 'danger',
            icon: 'BellIcon',
          },
        })
      }
    },
    currency(value) {
      return `$${this.$options.filters.currency(value)}`
    },
    remove(data) {
      this.items.splice(data.index, 1)

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          text: 'Debe presionar el botón azul "Guardar" para salvar los cambios',
          title: 'Borrado',
          icon: 'CoffeeIcon',
          variant: 'secondary',
        },
      })
    },
    push(item) {
      this.items.push({
        cuentaLegales: this.item.accountNumberLegal,
        cuentaMinutas: this.item.accountNumberOutgo,
        idProducto: this.item.product.code,
        producto: this.item.product.label,
        limiteInferior: Number(item.lowerLimit),
        limiteSuperior: Number(item.topLimit),
        montoLegales: Number(item.legalAmount),
        montoMinutas: Number(item.outgoAmount),
      })

      this.subItem = {}
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          text: 'Debe presionar el botón azul "Guardar" para salvar los cambios',
          title: 'Agregado',
          icon: 'CoffeeIcon',
          variant: 'secondary',
        },
      })
    },
    save() {
      this.loading = true
      this.errorMessage = ''
      this.$http.post(`${environment.uri}/accounts/range`, this.items)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Datos guardados con exito!',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })

          this.loading = false
        })
        .catch(error => {
          console.error(error)
          this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
          this.loading = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No fue posible procesar su solicitud',
              variant: 'danger',
            },
          })
        })
    },
    async selectProduct(item) {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}/accounts/range/idProducto/${item.code}`)

        if (resp && resp.data) {
          this.items = resp.data
        }
      } catch (error) {
        console.error(error)
        this.errorMessage = 'Algo salio mal, intentalo mas tarde'
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
